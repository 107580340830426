// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal[data-v-0d9f4fe8] {
  overflow-y: hidden;
  z-index: 100000;
}
.modal-dialog[data-v-0d9f4fe8] {
  animation: zoom-in-zoom-out-0d9f4fe8 0.2s ease 1;
  place-content: center;
  min-width: 655px;
  max-width: 600px !important;
}
.modal-dialog.modal-fullscreen[data-v-0d9f4fe8] {
  min-width: 100%;
  max-width: 100%;
}
.modal-dialog.modal-fullscreen .modal-body[data-v-0d9f4fe8] {
  padding: 5px;
}
.modal-dialog.modal-fullscreen .modal-content[data-v-0d9f4fe8] {
  width: 100%;
}
@keyframes zoom-in-zoom-out-0d9f4fe8 {
0% {
    transform: scale(0.9, 0.9);
}
50% {
    transform: scale(0.95, 0.95);
}
100% {
    transform: scale(1, 1);
}
}
.modal-header[data-v-0d9f4fe8] {
  border-bottom: 0px;
}
.modal-large[data-v-0d9f4fe8] {
  min-width: 1130px;
  height: 635px;
}
.modal-content[data-v-0d9f4fe8] {
  border-radius: 5px;
  padding: 5px;
  width: auto;
  background: #F4f4f4;
  box-shadow: 0 0 8px 2px rgba(46, 46, 46, 0.33);
}
.header-title[data-v-0d9f4fe8] {
  text-align: center;
  color: #484848;
  text-transform: uppercase;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
